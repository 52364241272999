<template lang="pug">
    div
        header-component
        router-view
</template>
<script>

import HeaderComponent from '@/components/StudentLayout/HeaderComponent'
export default {
    name: 'StudentLayout', //nombre del componente
    components: {
        HeaderComponent
    },
    beforeMount() {
        //document.body.classList.add('hold-transition', 'sidebar-mini', 'layout-navbar-fixed')
    },
    destroyed() {
        //document.body.classList.remove('hold-transition', 'sidebar-mini', 'layout-navbar-fixed')
    }
}
</script>